import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Disclaimer as DsDisclaimer } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CARD_TRANSACTION_STATUS, STATUS } from 'qonto/constants/transactions.ts';
import type ZendeskLocalizationService from 'qonto/services/zendesk-localization.ts';
import { CardDeclinedReasons } from 'qonto/react/transactions/constants.ts';
import styles from './disclaimer.strict-module.css';

interface SidebarHeaderCardDisclaimerProps {
  declinedReason: CardDeclinedReasons;
  cardTransactionStatus: string;
  status: string;
}

const pendingDisclaimer = (
  cardTransactionStatus: string,
  zendeskLocalization: ZendeskLocalizationService
): ReactNode => {
  switch (cardTransactionStatus) {
    case CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_MORE_THAN_1000:
      return (
        <FormattedMessage
          id="transactions.sidebar.card_disclaimers.pending.pre_authorized.more_than_1000.body"
          values={{
            depositReleaseFaqLink: (
              <a
                className="body-link"
                data-test-more-1000-disclaimer-link=""
                href={zendeskLocalization.getLocalizedArticle(5165756)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="transactions.sidebar.card_disclaimers.pending.pre_authorized.more_than_1000.link" />
              </a>
            ),
          }}
        />
      );

    case CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_LESS_THAN_OR_EQUAL_TO_1000:
      return (
        <FormattedMessage
          id="transactions.sidebar.card_disclaimers.pending.pre_authorized.less_than_or_equal_to_1000.body"
          values={{
            merchantHoldFaqLink: (
              <a
                className="body-link"
                data-test-less-equal-1000-disclaimer-link=""
                href={zendeskLocalization.getLocalizedArticle(5165756)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="transactions.sidebar.card_disclaimers.pending.pre_authorized.less_than_or_equal_to_1000.link" />
              </a>
            ),
          }}
        />
      );

    case CARD_TRANSACTION_STATUS.COMPLETED_ZERO:
      return <FormattedMessage id="transactions.sidebar.card_disclaimers.pending.zero" />;

    default:
      return (
        <FormattedMessage
          id="transactions.sidebar.card_disclaimers.pending.default.body"
          values={{
            processingTransactionFaqLink: (
              <a
                className="body-link"
                data-test-pending-default-disclaimer-link=""
                href={zendeskLocalization.getLocalizedArticle(4359569)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="transactions.sidebar.card_disclaimers.pending.default.link" />
              </a>
            ),
          }}
        />
      );
  }
};

const reversedDisclaimer = (cardTransactionStatus: string): ReactNode => {
  return cardTransactionStatus === CARD_TRANSACTION_STATUS.CANCELED ? (
    <FormattedMessage id="transactions.sidebar.card_disclaimers.reverted.canceled" />
  ) : (
    <FormattedMessage id="transactions.sidebar.card_disclaimers.reverted.default" />
  );
};

const declinedDisclaimer = (declinedReason: CardDeclinedReasons): ReactNode => {
  switch (declinedReason) {
    case CardDeclinedReasons.ExpiredCard:
      return <FormattedMessage id="transactions.sidebar.card-disclaimers.declined.expired-card" />;
    case CardDeclinedReasons.SecurityViolation:
      return (
        <FormattedMessage id="transactions.sidebar.card-disclaimers.declined.fraud-suspicion" />
      );
    default:
      return null;
  }
};

export function SidebarHeaderCardDisclaimer({
  cardTransactionStatus,
  declinedReason,
  status,
}: SidebarHeaderCardDisclaimerProps): ReactNode {
  const zendeskLocalization = useEmberService('zendesk-localization');

  let text: ReactNode = null;
  let level: 'info' | 'error' = 'info';

  switch (status) {
    case STATUS.COMPLETED:
      if (cardTransactionStatus === CARD_TRANSACTION_STATUS.PRE_AUTHORIZED_COMPLETED) {
        text = (
          <FormattedMessage id="transactions.sidebar.card_disclaimers.completed.pre_authorized" />
        );
      }
      break;
    case STATUS.PENDING:
      text = pendingDisclaimer(cardTransactionStatus, zendeskLocalization);
      break;
    case STATUS.REVERSED:
      text = reversedDisclaimer(cardTransactionStatus);
      break;
    case STATUS.DECLINED:
      text = declinedDisclaimer(declinedReason);
      level = 'error';
      break;
  }

  const Disclaimer = level === 'error' ? DsDisclaimer.Block : DsDisclaimer.Inline;

  return (
    Boolean(text) && (
      <div className={styles.disclaimer}>
        <Disclaimer data-test-card-transaction-disclaimer="" level={level}>
          {text}
        </Disclaimer>
      </div>
    )
  );
}
