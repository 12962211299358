import { type PropsWithChildren, type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { FlowHeader, type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import { Preview, type PreviewProps } from '../../preview';
import styles from './payment-link-form-step.strict-module.css';

interface PaymentLinkFormStepProps extends PropsWithChildren {
  headerProps: FlowHeaderProps;
  footerProps: {
    isHidden?: boolean;
    buttonLabel: string;
    onClickNext: () => void;
    isButtonDisabled?: boolean;
    isButtonLoading?: boolean;
  };
  onClose: () => void;
  previewProps: PreviewProps;
}

export function PaymentLinkFormStep({
  children,
  headerProps,
  footerProps,
  previewProps,
  onClose,
}: PaymentLinkFormStepProps): ReactNode {
  return (
    <>
      <FlowHeader
        breadcrumb={headerProps.breadcrumb}
        className={styles.header}
        onClose={onClose}
        title={headerProps.title}
      />
      <div className={styles.container}>
        <div className={styles['form-section']} data-test-scrollable>
          <div className={styles['form-content']}>
            <div className={styles['form-wrapper']}>
              <div className={styles['form-fields']}>{children}</div>
            </div>
          </div>
          {!footerProps.isHidden ? (
            <div className={styles.footer}>
              <Button
                data-test-payment-link-continue-button
                isDisabled={footerProps.isButtonDisabled}
                isLoading={footerProps.isButtonLoading}
                onPress={footerProps.onClickNext}
              >
                {footerProps.buttonLabel}
              </Button>
            </div>
          ) : null}
        </div>
        <aside
          className={styles.preview}
          ref={element => {
            element?.setAttribute('inert', '');
          }}
        >
          <div className={styles['preview-content']}>
            <Preview
              amount={previewProps.amount}
              hideEmailField={previewProps.hideEmailField}
              items={previewProps.items}
              methods={previewProps.methods}
              organizationName={previewProps.organizationName}
            />
          </div>
        </aside>
      </div>
    </>
  );
}
