import { useQuery } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useToast } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';

interface PdfDownloadResult {
  isPending: boolean;
}

export const usePdfDownload = (
  accountId: string,
  enabled: boolean,
  resetCertificateState?: () => void
): PdfDownloadResult => {
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const sentry = useEmberService('sentry');
  const fetchApi = useFetchApi();

  const errorMessage = formatMessage({
    id: 'requests.mileage.steps.journey.errors.something-wrong',
  });

  const query = useQuery({
    queryKey: ['certificate', accountId],
    queryFn: async () => {
      const response = await fetchApi(`v5/bank_accounts/${accountId}/account_certificate`, {
        method: 'GET',
      });

      if (response.status === 202) {
        return { status: response.status };
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch certificate: ${response.statusText}`);
      }

      showToast({
        text: formatMessage({ id: 'bank_accounts.certificate.toast-message' }),
        type: 'success',
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Certificate.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      resetCertificateState?.();

      return { status: response.status };
    },
    enabled: enabled && Boolean(accountId),
    refetchInterval: 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query.isError) {
      sentry.captureException(query.error);
      showToast({
        text: errorMessage,
        type: 'error',
      });

      resetCertificateState?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run effect when isError changes or resetCertificateState changes
  }, [query.isError, resetCertificateState]);

  return { isPending: query.isLoading };
};
