import { type ReactNode } from 'react';
import cx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@repo/design-system-kit';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort';
import styles from './header.strict-module.css';

type AriaSort = 'none' | 'ascending' | 'descending';
type SortField = 'request_type' | 'initiator' | 'created_at';
type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  allSelected?: boolean;
  toggleAllSelected?: () => void;
  handleSortBy?: (sort: SortFull) => void;
  sortBy?: SortFull;
  isApproveCompactView?: boolean;
  isSidepanelOpen?: boolean;
}

export function Header({
  allSelected = false,
  toggleAllSelected,
  handleSortBy,
  sortBy,
  isApproveCompactView,
  isSidepanelOpen,
}: HeaderProps): ReactNode {
  const isSmallScreen = useMediaQuery('(width < 1367px)');
  const sortField = sortBy?.split(':')[0] as SortField;
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;

  const getAriaSort = (columnField: string): AriaSort => {
    if (columnField === sortField) {
      return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
    }
    return 'none';
  };

  const handleSort = (field: SortField): void => {
    if (!handleSortBy) return;

    let order: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    handleSortBy(`${field}:${order}`);
  };

  return (
    <tr
      className={cx(
        isApproveCompactView && styles['row-compact'],
        isSidepanelOpen && styles['row-sidebar']
      )}
      data-test-request-table-header
      data-test-request-table-header-approver
    >
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
      <th
        className={cx(styles['header-cell'], styles['header-bulk-checkbox'])}
        data-test-table-header-bulk-select-all
        scope="col"
      >
        <Checkbox
          className={styles.checkbox}
          isSelected={allSelected}
          onChange={toggleAllSelected}
        />
      </th>
      <th
        aria-sort={getAriaSort('request_type')}
        className={styles['header-cell']}
        data-test-table-header-request-type
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'request_type' && styles.active)}
          onClick={() => {
            handleSort('request_type');
          }}
          type="button"
        >
          <FormattedMessage id="reimbursements.headers.type" />
          <SortIcon isActive={sortField === 'request_type'} isAscending={isAscending} />
        </button>
      </th>
      <th
        aria-sort={getAriaSort('initiator')}
        className={styles['header-cell']}
        data-test-table-header-request-initiator
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'initiator' && styles.active)}
          onClick={() => {
            handleSort('initiator');
          }}
          type="button"
        >
          <FormattedMessage id="reimbursements.headers.requester" />
          <SortIcon isActive={sortField === 'initiator'} isAscending={isAscending} />
        </button>
      </th>
      <th
        aria-sort={getAriaSort('created_at')}
        className={styles['header-cell']}
        data-test-table-header-request-date
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'created_at' && styles.active)}
          onClick={() => {
            handleSort('created_at');
          }}
          type="button"
        >
          <FormattedMessage id="reimbursements.headers.request-date" />
          <SortIcon isActive={sortField === 'created_at'} isAscending={isAscending} />
        </button>
      </th>
      <th
        className={cx(styles['header-cell'], styles['header-amount'])}
        data-test-table-header-request-amount
        scope="col"
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="reimbursements.headers.amount" />
        </div>
      </th>
      <th
        className={cx(styles['header-cell'], styles['header-receipt'])}
        data-test-table-header-request-receipt
        scope="col"
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="reimbursements.headers.receipt" />
        </div>
      </th>
      {isSmallScreen || !isSidepanelOpen ? (
        <th
          aria-hidden={isSidepanelOpen}
          className={cx(styles['header-cell'], styles['header-approval'])}
          data-test-request-table-approver-header-approval
          scope="col"
        />
      ) : null}
      <th
        aria-hidden="true"
        className={cx(styles['header-cell'], styles.empty)}
        data-test-request-table-approver-header-cell-empty
        scope="col"
      />
    </tr>
  );
}
