import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from '@tanstack/react-query';
import { cashFlowCategoriesNamespaceV2 } from 'qonto/constants/hosts';
import { type FetchFunction, useFetchApi } from './use-fetch-api';

export interface AutofillCashFlowForecastRequestPayload {
  category_id: string;
}

export const autofillCashFlowForecast = async (
  fetchApi: FetchFunction,
  requestBody: AutofillCashFlowForecastRequestPayload
): Promise<void> => {
  const endpointUrl = `${cashFlowCategoriesNamespaceV2}/cash-flow/timeseries/forecasts/auto-fill`;
  const response = await fetchApi(endpointUrl, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`Could not autofill forecast entries: ${response.statusText}`);
  }
};

export const useAutofillCashflowForecast = (
  options?: UseMutationOptions<void, Error, AutofillCashFlowForecastRequestPayload>
): UseMutationResult<void, Error, AutofillCashFlowForecastRequestPayload> => {
  const fetchApi = useFetchApi();

  return useMutation({
    mutationFn: request => autofillCashFlowForecast(fetchApi, request),
    ...options,
  });
};
