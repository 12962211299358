import type { ReactElement } from 'react';
import { SubscriptionHeader } from '@repo/domain-kit/pricing';
import styles from './styles.strict-module.css';

interface ConfirmLayoutProps {
  children: ReactElement;
  header: ReactElement | string;
  subtitle?: ReactElement;
  testId: string;
}

export function ConfirmLayout({
  header,
  subtitle,
  children,
  testId,
}: ConfirmLayoutProps): ReactElement {
  return (
    <div className={styles.confirmLayout} data-test-confirm-layout {...{ [testId]: 'upgrade' }}>
      <div className={styles.wrapper}>
        <SubscriptionHeader title={header} />
        {subtitle ? (
          <p className="body-1" data-test-confirm-layout-subtitle>
            {subtitle}
          </p>
        ) : null}
        <div className={styles.content} data-test-confirm-layout-content>
          {children}
        </div>
      </div>
    </div>
  );
}
