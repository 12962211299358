import {
  useInfiniteQuery,
  type InfiniteData,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { type FetchFunction, useFetchApi } from 'qonto/react/hooks/use-fetch-api.ts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager.ts';
import { transactionNamespace } from 'qonto/constants/hosts.ts';
import {
  FilterConditional,
  FilterExpressionOperator,
  type FilterGroup,
} from 'qonto/react/models/filters.ts';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display';
import { getCategoriesIds } from 'qonto/react/components/cash-flow/utils/transactions-filter';
import type TransactionModel from 'qonto/models/transaction';

interface DateRange {
  start: number;
  end: number;
}
interface SearchTransactionsPayload {
  organization_id: string;
  sort: {
    property: string;
    direction: string;
  };
  filter_group: FilterGroup;
  pagination: {
    per_page: number;
    page: number;
  };
  bank_account_ids?: string[];
}

interface RawTransaction extends TransactionModel {
  bankAccountId: string;
  categoryAssignment: {
    id: string;
    source: string;
  } | null;
}

export interface SearchTransactionsResult {
  transactions: RawTransaction[];
  meta: {
    total_count: number;
    current_page: number;
    next_page: number;
  };
}

export const fetchTransactions = async (
  fetchApi: FetchFunction,
  payload: SearchTransactionsPayload
): Promise<SearchTransactionsResult> => {
  const response = await fetchApi(`${transactionNamespace}/transactions/search`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`Error fetching transactions`);
  }

  return (await response.json()) as SearchTransactionsResult;
};

export function useFetchInfiniteTransactions({
  categories,
  dateRange,
  bankAccountIds,
  isFlowSelected,
}: {
  categories: CategoriesTableRow[];
  dateRange: DateRange;
  bankAccountIds: string[];
  isFlowSelected: boolean;
}): UseInfiniteQueryResult<InfiniteData<SearchTransactionsResult>> {
  const fetchApi = useFetchApi();
  const { organization } = useOrganizationManager();
  const { id: organizationId } = organization;

  const searchTransactions = (page: number): Promise<SearchTransactionsResult> => {
    const payload = getSearchTransactionsPayload({
      organizationId,
      categories,
      dateRange,
      bankAccountIds,
      page,
      isFlowSelected,
    });

    return fetchTransactions(fetchApi, payload);
  };

  return useInfiniteQuery({
    queryKey: ['infinite-transactions', organizationId, categories, dateRange, bankAccountIds],
    queryFn: ({ pageParam }) => searchTransactions(pageParam),
    getNextPageParam: lastPage => {
      return lastPage.meta.next_page;
    },
    initialPageParam: 1,
  });
}

const getSearchTransactionsPayload = ({
  organizationId,
  categories,
  dateRange,
  bankAccountIds,
  page,
  isFlowSelected,
}: {
  organizationId: string;
  categories: CategoriesTableRow[];
  dateRange: DateRange;
  bankAccountIds: string[];
  page: number;
  isFlowSelected: boolean;
}): SearchTransactionsPayload => {
  const basePayload = {
    organization_id: organizationId,
    sort: {
      property: 'emitted_at',
      direction: 'desc',
    },
    filter_group: buildFilterGroup(categories, dateRange, isFlowSelected),
    pagination: {
      per_page: 25,
      page,
    },
  };

  if (bankAccountIds.length > 0) {
    return {
      ...basePayload,
      bank_account_ids: bankAccountIds,
    };
  }

  return basePayload;
};

export const buildFilterGroup = (
  categories: CategoriesTableRow[],
  dateRange: DateRange,
  isFlowSelected: boolean
): FilterGroup => {
  const property = 'category_assignment_category_id';
  const isUncategorized = categories.length === 1 && categories[0]?.type === 'uncategorized';

  const filterOutDeclinedExpression = {
    property: 'status',
    operator: FilterExpressionOperator.NotIn,
    values: ['declined'],
  };

  const emmittedAtExpression = {
    property: 'emitted_at',
    operator: FilterExpressionOperator.Within,
    values: [dateRange.start, dateRange.end],
  };

  const validCategoriesExpressions = {
    conditional: FilterConditional.And,
    expressions: [
      {
        property,
        operator: FilterExpressionOperator.In,
        values: getCategoriesIds(categories),
      },
      filterOutDeclinedExpression,
      emmittedAtExpression,
    ],
  };

  const uncategorizedExpressions = {
    conditional: FilterConditional.And,
    expressions: [
      {
        property,
        operator: FilterExpressionOperator.NotExists,
        values: [],
      },
      filterOutDeclinedExpression,
      emmittedAtExpression,
    ],
  };

  const getExpressions = (): FilterGroup[] => {
    if (isFlowSelected) {
      return [validCategoriesExpressions, uncategorizedExpressions];
    }

    if (isUncategorized) {
      return [uncategorizedExpressions];
    }

    return [validCategoriesExpressions];
  };

  return {
    conditional: FilterConditional.Or,
    expressions: getExpressions(),
  };
};
