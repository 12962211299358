import { type ReactNode, useRef, useState } from 'react';
import { Button, LottiePlayer, type LottieRefCurrentProps } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import cx from 'clsx';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './styles.strict-module.css';

export const QONTO_INVOICING_FIRST_TIME_EXPERIENCE_STORAGE_KEY =
  'qonto-invoicing-first-time-experience';

export function QontoInvoicingLandingPage(): ReactNode {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const localeManager = useEmberService('localeManager');
  const flowLinkManager = useEmberService('flowLinkManager');
  const { organization } = useOrganizationManager();
  const router = useRouter();
  const segment = useSegment();
  const [shouldAnimateText, setShouldAnimateText] = useState(false);
  const [isFirstTimeExperience, setIsFirstTimeExperience] = useState(
    () => !safeLocalStorage.getItem(QONTO_INVOICING_FIRST_TIME_EXPERIENCE_STORAGE_KEY)
  );

  const lottiePath =
    localeManager.locale === 'fr'
      ? '/lotties/qonto-invoicing/lottie-reveal-central-page-fr.json'
      : '/lotties/qonto-invoicing/lottie-reveal-central-page-en.json';

  const fullScreenLottiePath =
    localeManager.locale === 'fr'
      ? '/lotties/qonto-invoicing/lottie-reveal-full-page-fr.json'
      : '/lotties/qonto-invoicing/lottie-reveal-full-page-en.json';

  const path = isFirstTimeExperience ? fullScreenLottiePath : lottiePath;

  const handleCustomizeTemplateCtaClick = (): void => {
    segment.track('overview_qonto-invoicing_empty-state_customize-template_clicked');
    void flowLinkManager.transitionTo({
      name: 'account-receivable-onboarding',
      stepId: 'customization-logo',
      queryParams: { origin: router.pathname },
    });
  };

  const handleCreateInvoiceCtaClick = (): void => {
    segment.track('overview_qonto-invoicing_empty-state_create-invoice_clicked');
    void router.push(`/organizations/${organization.slug}/receivable-invoices/new`);
  };

  if (isFirstTimeExperience) {
    return (
      <div className={styles.pageContainer} data-test-qonto-invoicing-activation-homepage>
        <LottiePlayer
          className={styles.fullScreenAnimation}
          loop={false}
          onComplete={() => {
            setShouldAnimateText(true);
            safeLocalStorage.setItem(QONTO_INVOICING_FIRST_TIME_EXPERIENCE_STORAGE_KEY, 'true');
            setIsFirstTimeExperience(false);
          }}
          path={path}
        />
      </div>
    );
  }

  return (
    <div className={styles.pageContainer} data-test-qonto-invoicing-activation-homepage>
      <div
        className={cx(
          styles.firstSectionContainer,
          shouldAnimateText ? styles.animate : styles.showWithoutAnimation
        )}
      >
        <div className={styles.textWrapper}>
          <h1 className="title-1 mb-8" data-test-qonto-invoicing-homepage-title>
            <FormattedMessage id="qonto-invoicing.overview.activation-start.title" />
          </h1>
          <p className="body-1" data-test-qonto-invoicing-homepage-subtitle>
            <FormattedMessage id="qonto-invoicing.overview.activation-start.subtitle" />
          </p>
        </div>
        <div className={styles.ctaWrapper}>
          <Button
            className="mr-16"
            data-test-id="qonto-invoicing-homepage-customize-template-cta"
            onPress={handleCustomizeTemplateCtaClick}
            variant="primary"
          >
            <FormattedMessage id="qonto-invoicing.overview.activation-start.customize-template-cta" />
          </Button>

          <Button
            data-test-id="qonto-invoicing-homepage-create-invoice-cta"
            onPress={handleCreateInvoiceCtaClick}
            variant="secondary"
          >
            <FormattedMessage id="qonto-invoicing.overview.activation-start.create-invoice-cta" />
          </Button>
        </div>
      </div>

      <div className={styles.secondSectionContainer}>
        <LottiePlayer
          autoplay
          className={styles.illustration}
          data-test-qonto-invoicing-homepage-lottie={path}
          loop={false}
          lottieRef={lottieRef}
          path={path}
        />
      </div>
    </div>
  );
}
