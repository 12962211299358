import { type IntlShape } from 'react-intl';
import { Addons } from 'qonto/constants/addons';
import { PricePlanGroupCodes } from 'qonto/constants/price-plan';

export const getProductName = (intl: IntlShape, code: string): string => {
  switch (code as Addons | PricePlanGroupCodes) {
    case Addons.AccountsPayable:
      return intl.formatMessage({
        id: 'subscription.consumption-table.addon-overview.addon-list.accounts-payable.title',
      });
    case Addons.AccountsReceivable:
      return intl.formatMessage({
        id: 'subscription.consumption-table.addon-overview.addon-list.accounts-receivable.title',
      });
    case Addons.CashFlowManagement:
      return intl.formatMessage({
        id: 'subscription.consumption-table.addon-overview.addon-list.cash-flow-management.title',
      });
    case Addons.ExpenseSpendManagement:
      return intl.formatMessage({
        id: 'subscription.consumption-table.addon-overview.addon-list.expense-spend.title',
      });
    case PricePlanGroupCodes.SoloBasic:
      return intl.formatMessage({
        id: 'subscription.change.plans.basic',
      });
    case PricePlanGroupCodes.SoloSmart:
      return intl.formatMessage({
        id: 'subscription.change.plans.smart',
      });
    case PricePlanGroupCodes.SoloPremium:
      return intl.formatMessage({
        id: 'subscription.change.plans.premium',
      });
    case PricePlanGroupCodes.TeamEssential:
      return intl.formatMessage({
        id: 'subscription.change.plans.essential',
      });
    case PricePlanGroupCodes.TeamBusiness:
      return intl.formatMessage({
        id: 'subscription.change.plans.business',
      });
    case PricePlanGroupCodes.TeamEnterprise:
      return intl.formatMessage({
        id: 'subscription.change.plans.enterprise',
      });
    default:
      return '';
  }
};
