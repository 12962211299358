import { useCallback, useEffect, useRef } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { parseContentDisposition } from 'qonto/utils/parse-content-disposition';

interface UseDownloadWithHeadersProps {
  href: string;
  fallbackFilename?: string;
  /**
   * Override the creation of a new link by using an existing one
   */
  customLink?: HTMLAnchorElement | null;
}

export function useDownloadWithHeaders({
  href,
  fallbackFilename = 'document.pdf',
  customLink,
}: UseDownloadWithHeadersProps): {
  downloadFile: () => Promise<void>;
} {
  const networkManager = useEmberService('network-manager');

  const objectUrlRef = useRef<string | null>(null);

  const cleanup = useCallback(() => {
    if (objectUrlRef.current) {
      URL.revokeObjectURL(objectUrlRef.current);
      objectUrlRef.current = null;
    }
  }, []);

  const downloadFile = useCallback(async () => {
    async function fetchFile(): Promise<Response> {
      const response = await fetch(href, {
        method: 'GET',
        headers: { ...networkManager.requestHeaders },
        credentials: 'include',
      });

      return response;
    }

    const response = await fetchFile();

    let filename;
    const filenameHeader = parseContentDisposition(response.headers).get('filename');
    if (typeof filenameHeader === 'string') {
      filename = decodeURIComponent(filenameHeader);
    }

    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    objectUrlRef.current = objectUrl;

    // Create temporary link and trigger download
    const link = customLink ?? document.createElement('a');
    link.href = objectUrl;
    link.download = filename || fallbackFilename;
    if (!customLink) document.body.appendChild(link);
    link.click();
    if (!customLink) document.body.removeChild(link);

    // Cleanup object URL after download starts
    cleanup();
  }, [cleanup, href, networkManager, fallbackFilename, customLink]);

  // Cleanup on unmount
  useEffect(() => {
    return cleanup;
  }, [cleanup]);
  return { downloadFile };
}

export const downloadHook = {
  useDownloadWithHeaders,
};
