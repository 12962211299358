import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import {
  ChoiceChip,
  Disclaimer,
  SelectorToggle,
  TextField,
  useToast,
} from '@repo/design-system-kit';
import type { BasketItem } from '@repo/domain-kit/payment-links';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { Amount } from 'qonto/react/models/amount';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import type PaymentLinkModel from 'qonto/models/payment-link';
import { receivableInvoicesURL } from 'qonto/constants/hosts';
import { useCreatePaymentLink } from 'qonto/react/hooks/use-create-payment-link';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { PaymentLinkFormStep } from './payment-link-form-step';
import styles from './settings.strict-module.css';

type Method = PaymentLinkMethodModel['method'];

interface SettingsProps {
  breadcrumb: FlowHeaderProps['breadcrumb'];
  onClose: () => void;
  onContinue: (checkoutUrl: string) => void;
  onSettingsChange: (settings: PaymentLinkModel['settings']) => void;
  selectedItems: BasketItem[];
  selectedMethods: Method[];
  totalAmount: Amount<string>;
  organizationName: string;
  settings: PaymentLinkModel['settings'];
}

function isWebsiteValid(redirectUrl: string): boolean {
  try {
    const url = new URL(redirectUrl);
    return url.protocol === 'https:' && !url.href.includes('@');
  } catch {
    return false;
  }
}

export function Settings({
  breadcrumb,
  onClose,
  onContinue,
  onSettingsChange,
  selectedItems,
  selectedMethods,
  totalAmount,
  organizationName,
  settings,
}: SettingsProps): ReactNode {
  const sentry = useEmberService('sentry');
  const { formatMessage } = useIntl();
  const [websiteError, setWebsiteError] = useState<string | undefined>();
  const [redirectionIsChecked, setRedirectionIsChecked] = useState(false);
  const { showToast } = useToast();
  const { mutate: createPaymentLink, isPending } = useCreatePaymentLink();

  useEffect(() => {
    setWebsiteError(undefined);
  }, [redirectionIsChecked]);

  const handleLinkTypeChange = (value: 'reusable' | 'one-off'): void => {
    onSettingsChange({
      reusable: value === 'reusable',
      redirectUrl: settings.redirectUrl,
    });
  };

  const handleRedirectUrlChange = (value: string): void => {
    onSettingsChange({
      reusable: settings.reusable,
      redirectUrl: value,
    });
  };

  const handleOnCreatePaymentLink = useCallback(() => {
    setWebsiteError(undefined);

    if (redirectionIsChecked && !isWebsiteValid(settings.redirectUrl || '')) {
      setWebsiteError(formatMessage({ id: 'payment-link.create.step-3.redirection.error' }));
      return;
    }

    createPaymentLink(
      {
        amount: totalAmount,
        organizationName,
        items: selectedItems,
        potentialPaymentMethods: selectedMethods,
        settings,
      },
      {
        onSuccess: response => {
          const checkoutUrl = `${receivableInvoicesURL}/payment-links/${response.id}?resource_id=${response.attributes.payment_initiator.resource_id}`;
          onContinue(checkoutUrl);
        },
        onError: error => {
          sentry.captureException(error);
          showToast({
            text: formatMessage({ id: 'toasts.errors.try-later' }),
            type: 'error',
          });
        },
      }
    );
  }, [
    onContinue,
    organizationName,
    selectedItems,
    selectedMethods,
    settings,
    sentry,
    totalAmount,
    redirectionIsChecked,
    formatMessage,
    showToast,
    createPaymentLink,
  ]);

  const linkType = settings.reusable ? 'reusable' : 'one-off';

  return (
    <PaymentLinkFormStep
      footerProps={{
        buttonLabel: formatMessage({ id: 'btn.confirm' }),
        onClickNext: handleOnCreatePaymentLink,
        isButtonDisabled: isPending,
        isButtonLoading: isPending,
      }}
      headerProps={{
        breadcrumb,
        onClose,
        title: formatMessage({ id: 'payment-link.create.title' }),
      }}
      onClose={onClose}
      previewProps={{
        amount: totalAmount,
        items: selectedItems,
        methods: selectedMethods,
        organizationName,
      }}
    >
      <h2 className="title-3 mb-16" data-test-settings-title>
        <FormattedMessage id="payment-link.create.step-3.title" />
      </h2>

      <div className={styles.settingsWrapper}>
        <div className={styles.typeSelector} data-test-link-type-selector>
          <div className={styles.typeHeader}>
            <div className={styles.icon}>
              <StaticThemedAsset
                assetPath="/illustrations/ill-sspot-invoices-reusable-payment-link.svg"
                type="image"
              />
            </div>
            <div className={styles.typeText}>
              <span className="body-1" data-test-selector-title>
                <FormattedMessage id="payment-link.create.step-3.link-type.title" />
              </span>
              <span className="body-2" data-test-selector-subtitle>
                <FormattedMessage id="payment-link.create.step-3.link-type.subtitle" />
              </span>
            </div>
          </div>
          <div className={styles.types}>
            <div className={styles.options}>
              <ChoiceChip
                checkedValues={[linkType]}
                onChange={() => {
                  handleLinkTypeChange('reusable');
                }}
                value="reusable"
              >
                <FormattedMessage id="payment-link.create.step-3.link-type.reusable" />
              </ChoiceChip>
              <ChoiceChip
                checkedValues={[linkType]}
                onChange={() => {
                  handleLinkTypeChange('one-off');
                }}
                value="one-off"
              >
                <FormattedMessage id="payment-link.create.step-3.link-type.one-off" />
              </ChoiceChip>
            </div>
            {!settings.reusable ? (
              <Disclaimer.Inline className={styles.disclaimer} level="info">
                <FormattedMessage id="payment-link.create.step-3.link-type.disclaimer" />
              </Disclaimer.Inline>
            ) : null}
          </div>
        </div>

        <SelectorToggle
          checked={redirectionIsChecked}
          content={
            <TextField
              className={styles.website}
              data-test-redirect-url-input
              defaultValue={settings.redirectUrl}
              errorMessage={websiteError}
              isInvalid={Boolean(websiteError)}
              label={formatMessage({ id: 'payment-link.create.step-3.redirection.field.label' })}
              onChange={handleRedirectUrlChange}
              placeholder={formatMessage({
                id: 'payment-link.create.step-3.redirection.field.placeholder',
              })}
            />
          }
          data-test-redirect-url-selector
          icon={
            <StaticThemedAsset
              assetPath="/illustrations/ill-sspot-invoices-redirection-link.svg"
              type="image"
            />
          }
          onChange={() => {
            setRedirectionIsChecked(!redirectionIsChecked);
          }}
          subtitle={formatMessage({ id: 'payment-link.create.step-3.redirection.subtitle' })}
          title={formatMessage({ id: 'payment-link.create.step-3.redirection.title' })}
        />
      </div>
    </PaymentLinkFormStep>
  );
}
