import { type BasketItem, type ItemFormErrors } from '@repo/domain-kit/payment-links';

export interface ItemValidationError {
  id: string;
  errors: ItemFormErrors;
}

export function validateBasketItem(item: BasketItem): ItemFormErrors {
  const errorList: ItemFormErrors = [];

  if (!item.title) errorList.push('title');
  if (!item.quantity) errorList.push('quantity');
  if (!Number(item.unitPrice.value)) errorList.push('unitPrice');
  if (!item.vatRate) errorList.push('vatRate');

  return errorList;
}

export function validateBasketItemList(items: BasketItem[]): ItemValidationError[] {
  const errors: ItemValidationError[] = [];

  items.forEach(item => {
    const errorList = validateBasketItem(item);
    if (errorList.length) {
      errors.push({ id: item.id, errors: errorList });
    }
  });

  return errors;
}
